<template>
  <div>
    <div>
      <img src="/imgs/banner.png" style="width:100%;"/>
    </div>
    <div>
      <img src="/imgs/tips.png" style="width:100%;"/>
    </div>
    <van-divider></van-divider>
    <h4 class="h-title">输入门窗信息</h4>
    <van-form @submit="onSubmit">
      <van-cell-group class="open-type-group">
        <van-cell title="窗型">
          <van-radio-group v-model="type" direction="horizontal">
            <van-radio name="t1" @click="type = 't1'">矩形</van-radio>
            <van-radio name="t2" @click="type = 't2'">U型</van-radio>
            <van-radio name="t3" @click="type = 't3'">L型</van-radio>
          </van-radio-group>
        </van-cell>
      </van-cell-group>
      <van-field
        name="width"
        v-model="width"
        type="number"
        label="宽度(mm)"
        placeholder="请输入宽度(毫米)"
        input-align="right"
      />
      <van-field
        name="height"
        v-model="height"
        type="number"
        label="高度(mm)"
        placeholder="请输入高度(毫米)"
        input-align="right"
      />
      <van-field
        readonly
        clickable
        name="productName"
        :value="productName"
        label="产品类型"
        placeholder=""
        @click="showPicker = true"
        input-align="right"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="productsList"
          @confirm="changeProduct"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
        readonly
        clickable
        name="colorName"
        :value="colorName"
        label="室内门窗颜色"
        placeholder=""
        @click="showColorPicker = true"
        input-align="right"
      />
      <van-popup v-model="showColorPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="colorList"
          @confirm="changeColor"
          @cancel="showColorPicker = false"
        />
      </van-popup>
      <van-field
        readonly
        clickable
        name="colorOutName"
        :value="colorOutName"
        label="室外门窗颜色"
        placeholder=""
        @click="showColorOutPicker = true"
        input-align="right"
      />
      <van-popup v-model="showColorOutPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="colorList"
          @confirm="changeColorOut"
          @cancel="showColorOutPicker = false"
        />
      </van-popup>
      <van-cell-group class="open-type-group">
        <van-cell title="开启方式">
          <van-radio-group v-model="openType" name="openType" direction="horizontal">
            <van-radio name="1" @click="openType = '1'">内开内倒</van-radio>
            <van-radio name="2" @click="openType = '2'">外开</van-radio>
          </van-radio-group>
        </van-cell>
      </van-cell-group>
      <van-cell-group class="open-type-group">
        <van-cell title="开启数量(扇)">
          <van-stepper v-model="openNum" @change="changeOpenNum" min="0" max="9" integer/>
        </van-cell>
      </van-cell-group>
      <van-cell-group class="open-type-group">
        <van-cell title="转角数量(个)">
          <van-stepper v-model="corner" @change="changeCorner" min="0" max="9" integer/>
        </van-cell>
      </van-cell-group>
      <van-field
        readonly
        clickable
        name="jlName"
        :value="jlName"
        label="外遮阳卷帘"
        placeholder=""
        @click="showJlPicker = true"
        input-align="right"
      />
      <van-popup v-model="showJlPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="jlList"
          @confirm="changeJl"
          @cancel="showJlPicker = false"
        />
      </van-popup>
      <template v-if="showJl">
      <van-field
        readonly
        clickable
        name="colorJlName"
        :value="colorJlName"
        label="卷帘颜色"
        placeholder=""
        @click="showColorJlPicker = true"
        input-align="right"
      />
      </template>
      <van-popup v-model="showColorJlPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="colorJlList"
          @confirm="changeColorJl"
          @cancel="showColorJlPicker = false"
        />
      </van-popup>
      <van-field
        name="name"
        v-model="name"
        type="text"
        label="姓名"
        placeholder="请输入您的姓名"
        input-align="right"
      />
      <van-field
        name="mobile"
        v-model="mobile"
        type="text"
        label="手机号"
        placeholder="请输入您的手机号"
        input-align="right"
      />
      <div style="margin: 16px;">
        <van-button block type="info" native-type="submit">
          立即报价
        </van-button>
      </div>
    </van-form>
    <van-dialog v-model="showDialog" title="计算结果" closeOnClickOverlay showCancelButton confirmButtonText="电话咨询" :beforeClose="callMe">
      <van-loading style="margin-top:20px;" color="#0A66A6" size="16px" :class="showLoading?'show-result':'hide-result'">计算中...</van-loading>
      <div :class="showResult?'show-result':'hide-result'" style="height:400px; overflow:auto;padding: 10px;" >
        <div style="margin:20px 0;"><van-tag color="#0A66A6" size="large">合计: ¥ {{info.cost}}</van-tag></div>
        <van-divider class="divider">详情</van-divider>
        <div></div>
        <van-cell-group>
          <van-cell title="窗型" :value="info.type" />
          <van-cell title="宽(mm)" :value="info.width" />
          <van-cell title="高(mm)" :value="info.height" />
          <van-cell title="开启方式" :value="info.open_type" />
          <van-cell title="开启数量(扇)" :value="info.open_num" />
          <van-cell title="转角数量(个)" :value="info.corner" />
          <van-cell title="室内门窗颜色" :value="info.color" />
          <van-cell title="室外门窗颜色" :value="info.color_out" />
          <van-cell v-if="info.jl_info" title="卷帘规格" :value="info.jl_info" />
          <van-cell v-if="info.color_jl" title="卷帘颜色" :value="info.color_jl" />
          <van-cell title="基础价" :value="info.base_cost" />
          <van-cell v-if="info.open_cost" title="开启价" :value="info.open_cost" />
          <van-cell title="纱窗价" :value="info.sc_cost" />
          <van-cell v-if="info.jl_cost"  title="卷帘价" :value="info.jl_cost" />
        </van-cell-group>
      </div>
      <van-notice-bar text="注: 产品安装费需上门勘察后另计" />
    </van-dialog>
  </div>
</template>

<script>
import { Form, Picker, Button, Popup, Field, CellGroup, Cell, RadioGroup, Radio, Toast, Divider, Stepper, Dialog, Loading, Tag, NoticeBar } from 'vant'
export default {
  // 注册组件
  components: {
    [Form.name]: Form,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Popup.name]: Popup,
    [Toast.name]: Toast,
    [Divider.name]: Divider,
    [Stepper.name]: Stepper,
    [Loading.name]: Loading,
    [Tag.name]: Tag,
    [NoticeBar.name]: NoticeBar,
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      productsList: ['焕彩PLUS系列'],
      product: 1,
      productName: '焕彩PLUS系列',
      showPicker: false,
      colorList: [
        '<div class="img-list"><img src="imgs/color/c_1.png" /><span>白色麻面</span></div>',
        '<div class="img-list"><img src="imgs/color/c_2.png" /><span>灰色麻面</span></div>',
        '<div class="img-list"><img src="imgs/color/c_3.png" /><span>绿色麻面</span></div>',
        '<div class="img-list"><img src="imgs/color/c_4.png" /><span>咖啡麻面</span></div>',
        '其他'
      ],
      color: '',
      colorName: '请选择颜色',
      showColorPicker: false,
      colorOut: '',
      colorOutName: '请选择颜色',
      showColorOutPicker: false,
      showJlPicker: false,
      jl: '',
      jlName: '请选择卷帘',
      jlList: ['不安装', 'DECO-VE37 遥控电动', 'DECO-VE37 手动皮带', 'DECO-VE37 手动曲柄'],
      jlPicker: false,
      showJl: false,
      colorJl: '',
      colorJlList: [
        '<div class="img-list"><img src="imgs/color/jc_1.png" /><span>银</span></div>',
        '<div class="img-list"><img src="imgs/color/jc_2.png" /><span>白</span></div>',
        '<div class="img-list"><img src="imgs/color/jc_3.png" /><span>米</span></div>',
        '<div class="img-list"><img src="imgs/color/jc_4.png" /><span>棕</span></div>'
      ],
      colorJlName: '请选择颜色',
      showColorJlPicker: false,
      typeList: ['矩形', 'U型', 'L型'],
      openTypeList: ['内开内倒', '外开'],
      type: 't1',
      width: '',
      height: '',
      corner: 0,
      openType: '1',
      openNum: 0,
      name: '',
      mobile: '',
      validFields: {
        color: '室内门窗颜色',
        colorOut: '室外门窗颜色',
        width: '宽度',
        height: '高度',
        openNum: '开启数量',
        corner: '转角数量',
        name: '姓名',
        mobile: '手机号'
      },
      info: {
        w: '',
        h: '',
        color: '',
        color_out: '',
        color_jl: '',
        jl_info: '',
        open_type: '',
        open_num: '',
        corner: ''
      },
      showDialog: false,
      showResult: false,
      showLoading: true
    }
  },
  created () {
    document.title = '特诺发门窗计算器'
  },
  mounted () {
    var lbs = document.querySelectorAll('.open-type-group .van-cell__title')
    lbs.forEach((lb, i) => {
      lb.classList.add('van-field__label')
    })
  },
  methods: {
    changeProduct (value, index) {
      this.product = index + 1
      this.productName = value
      this.showPicker = false
    },
    changeColor (value, index) {
      this.color = index + 1
      this.colorName = this.delHtmlTag(value)
      this.showColorPicker = false
    },
    changeColorOut (value, index) {
      this.colorOut = index + 1
      this.colorOutName = this.delHtmlTag(value)
      this.showColorOutPicker = false
    },
    changeColorJl (value, index) {
      this.colorJl = index + 1
      this.colorJlName = this.delHtmlTag(value)
      this.showColorJlPicker = false
    },
    changeJl (value, index) {
      this.jl = index
      this.jlName = value
      this.showJlPicker = false
      if (this.jl > 0) {
        this.showJl = true
        if (this.type !== 't1') {
          Toast('需技术上门勘察现场')
        }
      } else {
        this.showJl = false
      }
    },
    changeOpenNum (value) {
      this.openNum = value
    },
    changeCorner (value) {
      this.corner = value
    },
    onSubmit (values) {
      let valided = true
      values.color = this.color
      values.colorOut = this.colorOut
      for (var k in values) {
        if (values[k] === '') {
          if (k === 'color' || k === 'colorOut') {
            Toast('请选择' + this.validFields[k])
          } else {
            Toast('请输入' + this.validFields[k])
          }
          valided = false
          break
        }
        if (!(/^1[3456789]\d{9}$/.test(values.mobile))) {
          Toast('请输入正确的手机号')
          valided = false
          break
        }
      }
      values.type = this.type
      values.product = this.product
      values.openType = this.openType
      values.openNum = this.openNum
      values.corner = this.corner
      values.jl = this.jl
      values.colorJl = this.colorJl
      values.name = this.name
      values.mobile = this.mobile
      if (this.jl && this.colorJl === '') {
        Toast('请选择卷帘颜色')
        valided = false
      }
      if (valided) {
        this.calculator(values)
      }
    },
    calculator (params) {
      this.showResult = false
      this.showDialog = true
      this.showLoading = true
      this.https.get('tools/calc', params).then(response => {
        if (response.code === 0) {
          response.data.type = this.typeList[parseInt(response.data.type) - 1]
          response.data.open_type = this.openTypeList[parseInt(response.data.open_type) - 1]
          response.data.color = this.delHtmlTag(this.colorList[parseInt(response.data.color) - 1])
          response.data.color_out = this.delHtmlTag(this.colorList[parseInt(response.data.color_out) - 1])
          if (response.data.color_jl) {
            response.data.color_jl = this.delHtmlTag(this.colorJlList[parseInt(response.data.color_jl) - 1])
          }
          this.info = response.data
          this.showLoading = false
          this.showResult = true
        }
      })
    },
    delHtmlTag (str) {
      return str.replace(/<[^>]+>/g, '')
    },
    callMe (action, done) {
      if (action === 'confirm') {
        window.location.href = 'tel:4001800810'
        done()
      } else if (action === 'cancel') {
        done()
      }
    }
  }
}
</script>
<style>
  .open-type-group .van-cell__title {text-align: center;}
  .open-type-group .van-radio {margin-left: auto; max-width:90px;margin-right: 0;}
  .van-field__label {width: 7em !important;}
  [class*=van-hairline]::after {border:none;}
  .show-result {display: block;}
  .hide-result {display: none;}
  .divider {color: #0A66A6; border-color: #0A66A6; padding: 0 16px; }
  .h-title {color: #0C66A6;font-size:17px;}
  .van-dialog__confirm, .van-dialog__confirm:active { color: green !important; }
  .img-list *{display:inline-block; vertical-align: middle;}
  .img-list img{margin-right: 10px;width:30px;height:30px;}
  .van-button--info {
    background-color: #0A66A6;
    border: 1px solid #0A66A6;
  }
  .van-radio__icon--checked .van-icon {
    background-color: #0A66A6;
    border-color: #0A66A6;
  }
  .van-button--normal {
     font-size: 16px;
   }
  .van-cell:not(:last-child)::after {
    border: none;
  }
  .van-cell__title, .van-cell__value {
    text-align: left !important;
  }
  .van-stepper {
    text-align: right !important;
  }
</style>
